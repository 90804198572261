import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import MiddleCenter from './MiddleCenter';
import ExternalLink from './ExternalLink';
import { ReactComponent as Logo } from '../logo.svg';

import './WelcomeView.scss';

class WelcomeView extends React.Component {
  render() {
    return (
      <div className="welcome-view">
        <Scrollbars>
          <MiddleCenter>
            <div className="welcome">Welcome to Postcards!</div>
            <div className="text">Say hello to someone special today!</div>
            <Logo />
            <div className="credits">Created by the awesome people at <ExternalLink href="https://skyetel.com/">Skyetel</ExternalLink>.</div>
            <div className="credits-third-party">Icon made by <ExternalLink href="https://pixelbuddha.net/">Pixel Buddha</ExternalLink> from <ExternalLink href="https://www.flaticon.com/">www.flaticon.com</ExternalLink></div>
          </MiddleCenter>
        </Scrollbars>
      </div>
    );
  }
}

export default WelcomeView;