import React from 'react';
import './MiddleCenter.scss';

class MiddleCenter extends React.Component {
  render() {
    let className = 'middle-center';

    if (this.props.className)
      className += ' ' + this.props.className;

    return (<div className={className}>
      <div className="middle-center-inner">
        {this.props.children}
      </div>
    </div>);
  }
}

export default MiddleCenter;