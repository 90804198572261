function isUndefined(o) {
  return typeof o === 'undefined';
}

function isUndefinedOrNull(o) {
  return isUndefined(o) || o === null;
}

function isDefinedAndNotNull(o) {
  return !isUndefined(o) && o !== null;
}

export { isUndefined, isUndefinedOrNull, isDefinedAndNotNull };