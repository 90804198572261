import { observable, decorate } from "mobx";
import Text from './Text';

class UnreadTexts {
  constructor() {
    this.earliestUnreadTextId = null;
    this.markedAsRead = [];
    this.clear();
  }

  /*
  returns false if the text was already added or marked as read in this session
  */
  addText(text) {
    if (this.texts.find(t => t.id === text.id) || this.markedAsRead.find(t => t.id === text.id))
      return false;

    this.texts.push(text);

    if (text.isIncoming) {
      this.incomingCount++;

      if ((this.earliestUnreadTextId === null) || (text.id < this.earliestUnreadTextId))
        this.earliestUnreadTextId = text.id;
    }
    else
      this.outgoingCount++;

    return true;
  }

  markAsRead(token) {
    if (!this.texts.length)
      return Promise.resolve();

    const texts = this.texts;

    this.clear();

    this.markedAsRead.push(...texts);

    return Text.markAsViewed(token, texts);
  }

  clear() {
    this.incomingCount = 0;
    this.outgoingCount = 0;
    this.texts = [];
  }

  destroy() {
    Text.destroyTexts(this.texts);
  }
}

decorate(UnreadTexts, {
    incomingCount: observable
  , earliestUnreadTextId: observable
});

export default UnreadTexts;