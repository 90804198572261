import Auth from '../data/Auth';
import Dialog from '../data/Dialog';
import Contact from '../data/Contact';
import PhoneNumber from '../data/PhoneNumber';
import Text from '../data/Text';
import CombinedContactList from './CombinedContactList';

class Session {
  constructor({ auth, phoneNumber, combinedContactList }) {
    this.auth = auth;
    this.phoneNumber = phoneNumber;
    this.combinedContactList = combinedContactList;
  }

  static authorize(credentials) {
    return Auth.login(credentials)
      .then(auth => {
        const token = auth.token;

        return Promise.all([
            PhoneNumber.getAll(token)
          , Dialog.getAll(token)
          , Contact.getAll(token)
          , Text.poll(token)
        ]).then(([ phoneNumbers, dialogs, contacts, polledTexts ]) => {
          const phoneNumber = phoneNumbers.find(p => p.active);

          const combinedContactList = new CombinedContactList({ dialogs, contacts });
          combinedContactList.processPolledTexts(polledTexts);

          return new Session({ auth, phoneNumber, combinedContactList });
        });
      });
  }
}

export default Session;