import DataObject from "./DataObject";
import { executeRequest } from '../core/Request.js';
import { combineUrlParts } from '../helpers/Url';
import { observable, decorate } from 'mobx';

class Contact extends DataObject {
  static requestBase = 'contacts';

  constructor({ id, name, phoneNumber } = {}) {
    super();

    this.id = id;
    this.name = name;
    this.phoneNumber = phoneNumber;
  }

  static fromRawData({ id, number, display_name }) {
    return new Contact({
        id
      , name: display_name
      , phoneNumber: number
    });
  }

  static add(token, { name, phoneNumber }) {
    const data = {
      number: phoneNumber
    };

    if (name)
      data.display_name = name;

    return executeRequest({
        method: 'post'
      , request: this.requestBase
      , token
      , data
      , responseDataClass: this
    });

    /*
    }).then(() => {
      return new Promise(res => {
        setTimeout(res, 10000)
      })
    })
    */
  }

  updateName(token, newName) {
    const data = {
      display_name: newName
    };

    return executeRequest({
        method: 'patch'
      , request: combineUrlParts(Contact.requestBase, this.id)
      , token
      , data
    }).then(() => {
      this.name = newName;
    });
  }

  remove(token) {
    return executeRequest({
        method: 'delete'
      , request: combineUrlParts(Contact.requestBase, this.id)
      , token
    });
  }
}

decorate(Contact, {
  name: observable
});

export default Contact;