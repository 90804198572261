import React from 'react';
import { observer } from 'mobx-react';
import posed, { PoseGroup } from 'react-pose';
import { Scrollbars } from 'react-custom-scrollbars';
import TextView from './TextView';
import Spinner from './Spinner';
import { isDefinedAndNotNull } from '../helpers/Object';

import './DialogTextsView.scss';

const NewTextDiv = posed.div({
  enter: {
      y: -10
    , transition: {
      y: { from: -10, to: 0, duration: 300 }
    }
  }
});

class DialogTextsView extends React.Component {
  static markAsReadScrollTopMargin = 15;
  static fetchMoreHistoryScrollTopMargin = 300;

  constructor(props) {
    super(props);

    this.state = {
      initialNewTextCount: this.props.combinedContact.newTexts.texts.length
    };

    this.scrollbars = React.createRef();

    this.scrollStopped = this.scrollStopped.bind(this);
    this.windowFocus = this.windowFocus.bind(this);
  }

  newDialogShown() {
    const { dialogHistory, unreadTexts } = this.props.combinedContact;

    if (dialogHistory && !dialogHistory.hasFetched)
      dialogHistory.fetchTexts(this.props.session.auth.token, unreadTexts.earliestUnreadTextId);

    this.scrollbars.current.scrollToBottom();
    this.checkIfTextsWereRead();

    this.setState({
      initialNewTextCount: this.props.combinedContact.newTexts.texts.length
    });
  }

  windowFocus() {
    this.checkIfTextsWereRead();
  }

  componentDidMount() {
    this.newDialogShown();

    window.addEventListener('focus', this.windowFocus, false);
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.windowFocus, false);
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { scrollTop, scrollHeight } = this.scrollbars.current.getValues();

    return {
      scrollBottom: scrollHeight - scrollTop
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.combinedContact !== this.props.combinedContact) {
      this.newDialogShown();
      return;
    }

    if (!snapshot)
      return;

    const { scrollTop, scrollHeight, clientHeight } = this.scrollbars.current.getValues();

    const newScrollTop = scrollHeight - snapshot.scrollBottom;

    if (newScrollTop !== scrollTop)
      this.scrollbars.current.scrollTop(newScrollTop);
    else
      this.checkIfTextsWereRead();
  }

  fetchMoreHistory() {
    const { dialogHistory } = this.props.combinedContact;

    if (dialogHistory)
      dialogHistory.fetchTexts(this.props.session.auth.token);
  }

  scrollToBottom() {
    this.scrollbars.current.scrollToBottom();
  }

  checkIfTextsWereRead() {
    if (!document.hasFocus())
      return;

    const { scrollTop, scrollHeight, clientHeight } = this.scrollbars.current.getValues();

    if ((scrollHeight <= clientHeight)
      || ((scrollTop + clientHeight + DialogTextsView.markAsReadScrollTopMargin) >= scrollHeight))
      this.props.combinedContact.textsWereRead(this.props.session.auth.token);
  }

  scrollStopped() {
    const { scrollTop } = this.scrollbars.current.getValues();

    if (scrollTop <= DialogTextsView.fetchMoreHistoryScrollTopMargin)
      this.fetchMoreHistory();

    this.checkIfTextsWereRead();
  }

  render() {
    const { dialogHistory, newTexts, unreadTexts } = this.props.combinedContact;
    let historyTexts, isFetchingHistory;

    if (dialogHistory) {
      historyTexts = dialogHistory.texts;
      isFetchingHistory = dialogHistory.isFetching;

      const earliestUnreadTextId = unreadTexts.earliestUnreadTextId;

      if (isDefinedAndNotNull(unreadTexts.earliestUnreadTextId)) {
        historyTexts = historyTexts.filter(t => {
          return t.id < earliestUnreadTextId;
        });
      }
    }

    const initialNewTextCount = this.state.initialNewTextCount;
    const alreadySeenNewTexts = newTexts.texts.slice(0, initialNewTextCount);
    const reallyNewTexts = newTexts.texts.slice(initialNewTextCount);

    const renderText = text => (<TextView text={text} auth={this.props.session.auth}/>);

    return (
      <div className="dialog-texts">
        <Scrollbars ref={this.scrollbars} onScrollStop={this.scrollStopped}>
          <div className="dialog-texts-container">
            {isFetchingHistory && (<div className="fetching-history">
                <div className="inner">
                  <Spinner />
                </div>
              </div>)}
            {historyTexts && historyTexts.map(renderText)}
            {alreadySeenNewTexts.map(renderText)}
            <PoseGroup>
              {reallyNewTexts.map(text =>
                (<NewTextDiv key={text.generatedId}>
                  {renderText(text)}
                </NewTextDiv>))}
            </PoseGroup>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default observer(DialogTextsView);