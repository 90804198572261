import moment from '../helpers/moment';
import Dialog from './Dialog';
import Attachment from './Attachment';
import { executeRequest, executeListObjectsRequest } from '../core/Request.js';
import { combineUrlParts } from '../helpers/Url';

const timezone = moment.tz.guess();

class Text {
  static nextId = 0;

  constructor({ id, dialogId, dialog, text, sentTimestamp, isIncoming, attachments, isViewed } = {}) {
    this.id = id;
    this.dialogId = dialogId;
    this.dialog = dialog;
    this.text = text;
    this.sentTimestamp = sentTimestamp;
    this.isIncoming = isIncoming;
    this.attachments = attachments;
    this.isViewed = isViewed;

    this.generatedId = Text.nextId++;
  }

  static fromRawData({ id, dialog_id, dialog, message, sent, direction, media_hint,	viewed }) {
    const attachments = Object.keys(media_hint).map(url => {
      const { width, height } = media_hint[url];

      return new Attachment({
          url
        , width
        , height
      })
    });

    return new Text({
        id: +id
      , dialogId: dialog_id
      , dialog: Dialog.fromRawData(dialog)
      , text: message
      , sentTimestamp: moment.utc(sent).tz(timezone)
      , isIncoming: +direction === 0
      , attachments
      , isViewed: !!+viewed
    });
  }

  hasAttachments() {
    return this.attachments && this.attachments.length;
  }

  downloadAttachments(token) {
    if (this.attachments)
      this.attachments.forEach(attachment => {
        attachment.download(token);
      });
  }

  static markAsViewed(token, texts) {
    const data = {};

    texts.forEach(text => {
      data[text.id] = { viewed: 1 };
    });

    return executeRequest({
        method: 'post'
      , request: combineUrlParts('poll/messages', this.id)
      , token
      , data
    })
      .then(() => {
        texts.forEach(text => {
          text.isViewed = true;
        });
      });
  }

  static poll(token) {
    return executeListObjectsRequest({
        request: 'poll/messages'
      , token
      , responseDataClass: this
    }).then(polledMessages =>{
      polledMessages.reverse();
      return polledMessages;
    });
  }

  destroy() {
    if (this.attachments)
      Attachment.destroyAttachments(this.attachments);
  }

  static destroyTexts(texts) {
    texts.forEach(text => {
      text.destroy();
    });
  }
}

export default Text;


