import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import LoginForm from '../components/LoginForm';
import Session from '../core/Session';
import posed, { PoseGroup } from 'react-pose';
import Spinner from '../components/Spinner';
import { setDocumentTitle } from '../helpers/Document';
import { getDocumentTitle } from '../Constants';

import './UnauthorizedScreen.scss';

const PoseDiv = posed.div({
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 }
});

class UnauthorizedScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
        error: null
      , authorizing: false
    };

    this.loginFromSubmitted = this.loginFromSubmitted.bind(this);
  }

  loginFromSubmitted(credentials) {
    this.setState({
        error: null
      , authorizing: true
    });

    Session.authorize(credentials)
      .then(session => {
        const authorized = this.props.authorized;
        if (authorized)
          authorized(session);
      })
      .catch((error) => {
        //todo other errors

        this.setState({
            error: 'Invalid username and/or password'
          , authorizing: false
        });
      });
  }

  componentDidMount() {
    setDocumentTitle(getDocumentTitle());
  }

  render() {
    return (
      <div className="unauthorized-screen">
        <div className="login-form-container">
          <PoseGroup>
            {this.state.authorizing
              ? (<PoseDiv key="authorizing"><Spinner size="28" /></PoseDiv>)
              : (<PoseDiv key="credentials">
                <div className="info">Please sign in to Postcards</div>
                {this.state.error && (<Alert color="danger">{this.state.error}</Alert>)}
                <LoginForm submitted={this.loginFromSubmitted} />
              </PoseDiv>)}
          </PoseGroup>
        </div>
      </div>
    );
  }
}

export default UnauthorizedScreen;
