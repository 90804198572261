import React from 'react';
import { observer } from 'mobx-react';
import Linkify from 'linkifyjs/react';
import AttachmentView from './AttachmentView';
import { trimAsString } from '../helpers/String';
import { insertDelimiters } from '../helpers/Array';

import './TextView.scss';

class TextView extends React.Component {
  static timestampFormat = 'D MMM YYYY, h:mm A';

  render() {
    const text = this.props.text;

    const textClass = 'text ' + (text.isIncoming ? 'in' : 'out');

    const isOutgoing = text.constructor.isOutgoingText;

    let note;

    if (isOutgoing) {
      if (text.sendingCompleted) {
        if (text.sendError)
          note = (<div className="send-error">Failed to send</div>);
      }
      else
        note = (<div className="sending">Sending...</div>);
    }

    if (!note && text.sentTimestamp)
      note = (<div className="timestamp">
        {text.sentTimestamp.format(TextView.timestampFormat)}
      </div>);

    const textText = trimAsString(text.text);
    let textContents = null;

    const linkifyOptions = {
        className: ''
      , attributes: {
        rel: 'noopener noreferrer'
      }
    };

    if (textText.length > 0) {
      const lines = textText.split(/(?:\r\n|\r|\n)/g);
      textContents = (<Linkify tagName="div" className="bubble" options={linkifyOptions}>
          {insertDelimiters(lines, (<br/>))}
        </Linkify>);
    }

    return (
      <div className={textClass}>
        {textContents}
        {text.attachments && text.attachments.map(attachment =>
          <AttachmentView attachment={attachment} auth={this.props.auth}/>)}
        {note}
      </div>
    );
  }
}

export default observer(TextView);