import React from 'react';
import MiddleCenter from './MiddleCenter';
import Spinner from './Spinner';

import './SpinnerOverlay.scss';

class SpinnerOverlay extends React.Component {
  render() {
    return (
      <div className="spinner-overlay">
        <MiddleCenter>
          <Spinner />
        </MiddleCenter>
      </div>
    );
  }
}

export default SpinnerOverlay;