function insertDelimiters(arr, delimiterItem) {
  const result = [];
  const length = arr.length;

  arr.forEach((item, index) => {
    result.push(item);
    if (index < length - 1)
      result.push(delimiterItem);
  });

  return result;
}

export { insertDelimiters };