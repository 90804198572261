import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

class InfoModal extends React.Component {
  constructor(props) {
    super(props);

    this.hideClicked = this.hideClicked.bind(this);
  }

  hideClicked() {
    if (this.props.hide)
      this.props.hide();
  }

  render() {
    return (
      <Modal isOpen={this.props.shown} toggle={this.hideClicked}>
        <ModalHeader toggle={this.hideClicked}>{this.props.title}</ModalHeader>
        <ModalBody>
          {this.props.children}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.hideClicked}>OK</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default InfoModal;