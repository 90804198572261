import { decorate, observable } from "mobx";
import Dialog from './Dialog';
import Text from './Text';
import Attachment from './Attachment';
import { executeRequest } from '../core/Request.js';

class OutgoingText extends Text {
  static isOutgoingText = true;

  constructor({ from, to, text, attachment }) {
    const attachments = [];

    if (attachment) {
      const attachmentOptions = {};

      if (attachment.dimensionsReady) {
        attachmentOptions.width = attachment.width;
        attachmentOptions.height = attachment.height;
      }

      attachments.push(new Attachment(attachmentOptions));
    }

    super({
        text
      , isIncoming: false
      , attachments
    });

    this.dialog = null;

    this.from = from;
    this.to = to;
    this.attachment = attachment;

    this.sendingCompleted = false;
    this.sendError = null;
  }

  send(token) {
    const data = new FormData();
    data.append('message[from]', this.from);
    data.append('message[to]', this.to);
    data.append('message[text]', this.text);

    if (this.attachment)
      data.append('media[]', this.attachment.file);

    return executeRequest({
        method: 'post'
      , request: 'out'
      , token
      , data
    }).then((rawText) => {
      const text = Text.fromRawData(rawText);
      const dialog = new Dialog(rawText.dialog);

      this.id = text.id;
      this.dialogId = text.dialogId;
      this.text = text.text;
      this.sentTimestamp = text.sentTimestamp;
      this.isIncoming = text.isIncoming;

      this.attachments = text.attachments;

      this.isViewed = text.isViewed;

      this.dialog = dialog;

      this.downloadAttachments(token);

      this.sendingCompleted = true;

      /*
      no need to wait for the text to be marked as viewed so we don't :)
      */
      Text.markAsViewed(token, [this])
        .catch(() => { });
    }).catch(e => {
      this.sendError = e;
      this.sendingCompleted = true;
    });
  }
}

decorate(OutgoingText, {
    sendingCompleted: observable
  , sentTimestamp: observable
});

export default OutgoingText;