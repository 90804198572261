import { executeRequest } from '../core/Request.js';
import { toBase64 } from '../helpers/Base64';
import User from './User';

class Auth {
  constructor({ token, user } = {}) {
    if (!token || !user)
      throw new TypeError('Auth: missing parameter');

    this.token = token;
    this.user = user;
  }

  static fromRawData({ token, user }) {
    return new Auth({
        token
      , user: user ? User.fromRawData(user) : null
    });
  }

  static login({ username, password }) {
    return executeRequest({
        request: 'login'
      , method: 'post'
      , headers: { 'Authorization': 'Basic ' + toBase64(username + ':' + password) }
      , responseDataClass: Auth
    });
  }

  logout() {
    return executeRequest({
        request: 'logout'
      , method: 'post'
      , token: this.token
    });
  }
}

export default Auth;