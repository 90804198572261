import { observable, decorate } from "mobx";
import { isUndefinedOrNull, isDefinedAndNotNull } from '../helpers/Object';
import Text from "./Text";

class DialogTexts {
  constructor() {
    this.texts = [];
  }

  addText(text) {
    if (isDefinedAndNotNull(text.id)) {
      const findPredicate = t => {
        if (isUndefinedOrNull(t.id))
          return (!t.isIncoming
            && !text.isIncoming
            && (t.text === text.text)
            && (!t.hasAttachments() === !text.hasAttachments()));

        return t.id === text.id;
      };

      if (this.texts.find(findPredicate))
        return false;
    }

    this.texts.push(text);
  }

  destroy() {
    Text.destroyTexts(this.texts);
  }
}

decorate(DialogTexts, {
  texts: observable
});

export default DialogTexts;