import React, { Component } from 'react';
import { observer } from 'mobx-react';
import MailIcon from 'react-feather/dist/icons/mail';
import XIcon from 'react-feather/dist/icons/x';

import './HeaderView.scss';

class HeaderView extends Component {
  constructor(props) {
    super(props);

    this.logoClicked = this.logoClicked.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  logoClicked() {
    if (this.props.logoClicked)
      this.props.logoClicked();
  }

  logOut() {
    if (this.props.logOutClicked)
      this.props.logOutClicked();
  }

  render() {
    const session = this.props.session;

    return (
      <div className="header">
        <div className="logo" onClick={this.logoClicked}>
          <MailIcon size="21" /> Postcards
        </div>
        <div className="log-out-btn" onClick={this.logOut}><XIcon size="22" /></div>
        <div className="user">
          <div className="name">{session.auth.user.name}</div>
          <div className="phone-number">
            {session.phoneNumber
              ? session.phoneNumber.phoneNumber
              : 'No phone number'}
          </div>
        </div>
      </div>
    );
  }
}

export default observer(HeaderView);
