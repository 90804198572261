import React from 'react';
import Contact from  '../data/Contact';
import CombinedContact from '../core/CombinedContact';
import ContactModal from './ContactModal';

class AddContactModal extends React.Component {
  constructor(props) {
    super(props);

    this.executeSubmitRequest = this.executeSubmitRequest.bind(this);
  }

  executeSubmitRequest({ phoneNumber, name }) {
    return Contact.add(this.props.session.auth.token, { name, phoneNumber })
      .then((contact) => {
        const combinedContact = new CombinedContact({ contact });

        this.props.session.combinedContactList.addToTheTop(combinedContact);

        if (this.props.contactAdded)
          this.props.contactAdded(combinedContact);
      });
  }

  render() {
    const initialValues = {
        phoneNumber: ''
      , name: ''
    };

    return (
      <ContactModal
        title="Add Contact"
        submitBtnTitle="Add"
        submitErrorText="Failed to add the contact"
        shown={this.props.shown}
        hide={this.props.hide}
        initialValues={initialValues}
        session={this.props.session}
        executeSubmitRequest={this.executeSubmitRequest}
      />
    );
  }
}

export default AddContactModal;