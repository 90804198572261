import { trimAsString } from '../helpers/String';

class NewTextNotifications {
  constructor() {
    this.clear();
  }

  static requestPermission() {
    try {
      Notification.requestPermission();
    }
    catch (e) { }
  }

  show(text, combinedContact, onClick) {
    try {
      if (Notification.permission !== 'granted')
        return;

      let body = trimAsString(text.text);

      if (!body)
        body = text.hasAttachments ? 'Image received' : '';

      const notification = new Notification(combinedContact.name, {
        body
      });

      if (onClick)
        notification.onclick = onClick;

      let notifications = this.notificationsByCombinedContact[combinedContact.generatedId];
      if (!notifications)
        notifications = this.notificationsByCombinedContact[combinedContact.generatedId] = [];

      notifications.push(notification);
    }
    catch (e) { }
  }

  ensureHidden(combinedContact) {
    let notifications = this.notificationsByCombinedContact[combinedContact.generatedId];
    if (!notifications)
      return;

    NewTextNotifications.ensureNotificationsHidden(notifications);

    this.notificationsByCombinedContact[combinedContact.generatedId] = [];
  }

  static ensureNotificationsHidden(notifications) {
    try {
      notifications.forEach(notification => {
        notification.close();
      });
    }
    catch (e) { }
  }

  ensureAllHidden() {
    if ('values' in Object)
      Object.values(this.notificationsByCombinedContact).forEach(
        NewTextNotifications.ensureNotificationsHidden
      );

    this.clear();
  }

  clear() {
    this.notificationsByCombinedContact = {};
  }
}

export default NewTextNotifications;