class ImageFileAttachment {
  constructor(file) {
    this.file = file;
    this.dimensionsReady = false;

    var url = URL.createObjectURL(file);
    var img = new Image();

    const clearImg = () => {
      img.src = '';
      URL.revokeObjectURL(url);
    };

    img.onload = () => {
      this.width = img.naturalWidth;
      this.height = img.naturalHeight;
      this.dimensionsReady = true;

      clearImg();
    };

    img.onerror = () => {
      clearImg();
    };

    img.src = url;
  }
}

export default ImageFileAttachment;