import { decorate, observable } from "mobx";
import axios from 'axios';

class Attachment {
  constructor({ url, width, height } = {}) {
    this.url = url;
    this.width = width;
    this.height = height;
    this.objectURL = null;

    this.downloading = false;
    this.downloaded = false;
    this.downloadError = null;
  }

  download(token) {
    if (!this.url)
      return;

    if (this.downloading || this.downloaded)
      return;

    this.downloading = true;

    return axios(this.url + '?token=' + token, {
      responseType: 'blob'
    })
      .then((response) => {
        this.objectURL = URL.createObjectURL(response.data);
        this.downloading = false;
        this.downloaded = true;
      }).catch(e => {
        //todo
        this.downloadError = e;
        this.downloading = false;
        this.downloaded = true;
      });
  }

  destroy() {
    if (this.objectURL)
      URL.revokeObjectURL(this.objectURL);
  }

  static destroyAttachments(attachments) {
    attachments.forEach(attachment => {
      attachment.destroy();
    });
  }
}

decorate(Attachment, {
    downloaded: observable
  , width: observable
  , height: observable
});

export default Attachment;


