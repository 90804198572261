import React from 'react';
import { Button } from 'reactstrap';
import InputFiles from 'react-input-files';
import ImageIcon from 'react-feather/dist/icons/image';
import XIcon from 'react-feather/dist/icons/x';
import InfoModal from '../modals/InfoModal';
import ImageFileAttachment from '../core/ImageFileAttachment';
import { maxAttachmentSize } from '../Constants';
import { isDefinedAndNotNull } from '../helpers/Object';
import { fileSizeToText } from '../helpers/File';

import './AttachmentSelector.scss';

class AttachmentSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        infoModalTitle: ''
      , infoModalBody: null
      , infoModalShown: false
    };

    this.attachmentSelected = this.attachmentSelected.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.hideInfoModal = this.hideInfoModal.bind(this);
  }

  attachmentSelected(files) {
    if (!files || !files.length)
      return;

    const file = files[0];
    const size = file.size;

    if (isDefinedAndNotNull(size) && (size > maxAttachmentSize)) {
      this.setState({
          infoModalTitle: 'Attachment'
        , infoModalBody: `The selected file is ${fileSizeToText(size)} - the maximum allowed size is ${fileSizeToText(maxAttachmentSize)}.`
        , infoModalShown: true
      });

      return;
    }

    if (this.props.attachmentSelected)
      this.props.attachmentSelected(new ImageFileAttachment(file));
  }

  removeAttachment() {
    if (this.props.removeAttachment)
      this.props.removeAttachment();
  }

  hideInfoModal() {
    this.setState({
      infoModalShown: false
    });
  }

  render() {
    return (
      <div className="attachment-selector">
        <InputFiles onChange={this.attachmentSelected} accept="image/*">
          <Button className="image-btn" color="primary">
            <ImageIcon size="24" />{this.props.attachment ? (' ' + (this.props.attachment.file.name || 'Image selected')) : ''}
          </Button>
        </InputFiles>

        <Button className="remove-image-btn" disabled={!this.props.attachment} color="primary" onClick={this.removeAttachment}><XIcon size="24" /></Button>

        <InfoModal shown={this.state.infoModalShown} title={this.state.infoModalTitle} hide={this.hideInfoModal}>
          {this.state.infoModalBody}
        </InfoModal>
      </div>
    );
  }
}

export default AttachmentSelector;