/*
requestUrl should reference the folder where the backend API is deployed on the server
*/
const requestUrl = '../backend/api/';

const maxAttachmentSize = 1000000;

function getDocumentTitle(unreadCount) {
  return (unreadCount ? (`(${unreadCount}) `) : '') + 'Postcards';
}

export {
    requestUrl
  , maxAttachmentSize
  , getDocumentTitle
};