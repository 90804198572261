import { requestUrl } from '../Constants';
import { combineUrlParts } from '../helpers/Url';
import axios from 'axios';

function executeRequest({
    method = 'GET'
  , request
  , headers
  , token
  , params
  , data
  , responseIsArray
  , responseDataClass
  }) {
  const options = {
      url: combineUrlParts(requestUrl, request)
    , method
  };

  if (headers)
    options.headers = headers;

  if (token) {
    const tokenParams = {token};

    if (!params)
      params = tokenParams;
    else
      params = Object.assign(tokenParams, params);
  }

  if (params)
    options.params = params;

  if (data)
    options.data = data;

  return axios(options)
    .then((response) => {
      const data = response.data;

      return !responseDataClass
        ? data
        : (responseIsArray
          ? data.map(d => responseDataClass.fromRawData(d))
          : responseDataClass.fromRawData(data));
    });
}

function executeListObjectsRequest({ request, token, responseDataClass }) {
  let offset = 0;
  let limit = 40;

  let allDataObjects = [];

  const retrievePageDataObjects = (prevPageDataObjects) => {
    if (!prevPageDataObjects || (prevPageDataObjects.length >= limit)) {
      return executeRequest({
            request
          , token
          , params: {
              offset
            , limit
          }
          , responseIsArray: true
          , responseDataClass
      }).then((pageDataObjects) => {
          if (pageDataObjects.length > 0)
              allDataObjects = allDataObjects.concat(pageDataObjects);

          offset += limit;
          return retrievePageDataObjects(pageDataObjects);
      });
    }
  };

  return retrievePageDataObjects().then(() => allDataObjects);
}

export { executeRequest, executeListObjectsRequest };