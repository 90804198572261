class User {
  constructor({ id, email, name } = {}) {
    this.id = id;
    this.email = email;
    this.name = name;
  }

  static fromRawData({ id, email, display_name }) {
    return new User({
        id
      , email
      , name: display_name
    });
  }
}

export default User;