import { observable, decorate } from "mobx";
import CombinedContact from './CombinedContact';

class CombinedContactList {
  constructor({ dialogs, contacts }) {
    let combinedContacts = dialogs.map(dialog => {
      const contact = dialog.contact;

      if (contact)
        contacts = contacts.filter(c => c.id !== contact.id);

      return new CombinedContact({ dialog, contact });
    });

    contacts.forEach(contact => {
      combinedContacts.push(new CombinedContact({ contact }));
    });

    combinedContacts.sort((a, b) => {
      return a.name === b.name
        ? 0
        : ((a.name > b.name)
          ? 1
          : -1);
    });

    this.combinedContacts = combinedContacts;
  }

  add(combinedContact) {
    this.combinedContacts.push(combinedContact);
  }

  addToTheTop(combinedContact) {
    this.combinedContacts.splice(0, 0, combinedContact);
  }

  find({ dialog, contact }) {
    return this.combinedContacts.find(c => c.matches({ dialog, contact }));
  }

  findByPhoneNumber(phoneNumber) {
    return this.combinedContacts.find(c => c.phoneNumber === phoneNumber);
  }

  moveContactToTop(combinedContact) {
    const index = this.combinedContacts.findIndex(c => c === combinedContact);

    if (index > 0) {
      this.combinedContacts.splice(index, 1);
      this.combinedContacts.splice(0, 0, combinedContact);
    }
  }

  processPolledTexts(polledTexts, polledTextProcessed) {
    polledTexts.forEach(polledText => {
      const { dialog } = polledText;
      const { contact } = dialog;

      let combinedContact = this.find({ dialog, contact });

      if (!combinedContact) {
        combinedContact = new CombinedContact({ dialog, contact });
        this.combinedContacts.splice(0, 0, combinedContact);
      }

      const isNew = combinedContact.addPolledText(polledText);

      if (isNew && polledText.isIncoming)
        this.moveContactToTop(combinedContact);

      if (polledTextProcessed)
        polledTextProcessed(polledText, combinedContact, isNew);
    });
  }

  destroy() {
    CombinedContact.destroyCombinedContacts(this.combinedContacts);
  }
}

decorate(CombinedContactList, {
  combinedContacts: observable
});

export default CombinedContactList;