/*
taken from:
https://developer.mozilla.org/ru/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
*/

function toBase64(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
    return String.fromCharCode('0x' + p1);
  }));
}

function fromBase64(str) {
  return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

export { toBase64, fromBase64 };