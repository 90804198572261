import { trimAsString } from './String';

function isUrlDelimiter(char) {
  return (char === '\\') || (char === '/');
}

function combineUrlParts(...parts) {
  let url = '';

  if (parts && parts.length)
    parts.forEach((part) => {
      part = trimAsString(part);

      if (!part.length)
        return;

      if (!url.length) {
        url = part;
        return;
      }

      if (!isUrlDelimiter(url[url.length - 1]) && !isUrlDelimiter(part[0]))
        part = '/' + part;

      url += part;
    });

  return url;
}

export { combineUrlParts, isUrlDelimiter };