import React from 'react';
import { observer } from 'mobx-react';
import { Button, Form } from 'reactstrap';
import SendIcon from 'react-feather/dist/icons/send';
import AttachmentSelector from '../components/AttachmentSelector';
import { trimAsString, insertString } from '../helpers/String';

import './OutgoingTextEditor.scss';

class OutgoingTextEditor extends React.Component {
  constructor(props) {
    super(props);

    this.textarea = React.createRef();

    this.submitted = this.submitted.bind(this);
    this.textChanged = this.textChanged.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.attachmentSelected = this.attachmentSelected.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.send = this.send.bind(this);
    this.hideInfoModal = this.hideInfoModal.bind(this);
  }

  submitted(event) {
    event.preventDefault();

    this.send();
  }

  textChanged(event) {
    this.props.textDraft.text = event.target.value;
  }

  keyDown(event) {
    if (event.key !== 'Enter')
      return;

    event.preventDefault();

    if (!event.ctrlKey) {
      this.send();
      return;
    }

    const textDraft = this.props.textDraft;

    let selectionEnd = this.textarea.current.selectionEnd;

    textDraft.text = insertString(textDraft.text, selectionEnd,'\r\n');

    setTimeout(() => {
      this.textarea.current.selectionEnd = selectionEnd + 1;
    });
  }

  attachmentSelected(file) {
    this.props.textDraft.attachment = file;
  }

  removeAttachment() {
    this.props.textDraft.attachment = null;
  }

  send() {
    let { text, attachment } = this.props.textDraft;
    text = trimAsString(text);

    if ((!text.length && !attachment) || !this.props.sendText)
      return;

    this.props.sendText({ text, attachment });
    this.props.textDraft.clear();
  }

  hideInfoModal() {
    this.infoModalShown = false;
  }

  render() {
    const textDraft = this.props.textDraft;

    return (
      <Form className="outgoing-text-editor" onSubmit={this.submitted}>
        <textarea
          ref={this.textarea}
          className="form-control"
          autoFocus
          value={textDraft.text}
          onChange={this.textChanged}
          onKeyDown={this.keyDown}
        />

        <AttachmentSelector
          attachment={textDraft.attachment}
          attachmentSelected={this.attachmentSelected}
          removeAttachment={this.removeAttachment}
        />

        <Button className="send-btn" type="submit" color="primary"><SendIcon size="24" /></Button>
      </Form>
    );
  }
}

export default observer(OutgoingTextEditor);