import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import { observer } from 'mobx-react';
import { Scrollbars } from 'react-custom-scrollbars';
import PlusIcon from 'react-feather/dist/icons/plus-circle';
import Text from '../data/Text';
import ContactListItemView from './ContactListItemView';
import NewTextNotifications from '../core/NewTextNotifications';
import AddContactModal from '../modals/AddContactModal';
import { isDefinedAndNotNull } from '../helpers/Object';
import { setDocumentTitle } from '../helpers/Document';
import { getDocumentTitle } from '../Constants';

import './ContactListView.scss';

const ItemDiv = posed.div({
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 }
});

class ContactListView extends React.Component {
  static pollTextsInterval = 5000;

  constructor(props) {
    super(props);

    this.state = {
      addContactModalShown: false
    };

    this.scrollbars = React.createRef();
    this.newTextNotifications = new NewTextNotifications();

    this.contactSelected = this.contactSelected.bind(this);
    this.showAddContactModal = this.showAddContactModal.bind(this);
    this.hideAddContactModal = this.hideAddContactModal.bind(this);
    this.polledTextProcessed = this.polledTextProcessed.bind(this);
    this.contactAdded = this.contactAdded.bind(this);

    this.windowFocus = this.windowFocus.bind(this);
  }

  contactSelected(combinedContact) {
    if (this.props.contactSelected)
      this.props.contactSelected(combinedContact);
  }

  showAddContactModal() {
    this.setState({
      addContactModalShown: true
    });
  }

  hideAddContactModal() {
    this.setState({
      addContactModalShown: false
    });
  }

  contactAdded(combinedContact) {
    this.scrollbars.current.scrollToTop();

    this.contactSelected(combinedContact);
  }

  windowFocus() {
    this.ensureNewTextNotificationsHidden();
  }

  componentDidMount() {
    this.scheduleNextPollTexts();

    this.ensureNewTextNotificationsHidden();

    window.addEventListener('focus', this.windowFocus, false);
  }

  componentWillUnmount() {
    this.stopPollTexts();

    window.removeEventListener('focus', this.windowFocus, false);
  }

  ensureNewTextNotificationsHidden() {
    if (this.props.selectedContact)
      this.newTextNotifications.ensureHidden(this.props.selectedContact);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.session !== this.props.session) {
      this.newTextNotifications.ensureAllHidden();
      return;
    }

    if (prevProps.selectedContact !== this.props.selectedContact)
      this.ensureNewTextNotificationsHidden();
  }

  scheduleNextPollTexts() {
    this.pollTextsTimeoutId = setTimeout(() => {
      this.pollTexts();
    }, ContactListView.pollTextsInterval);
  }

  stopPollTexts() {
    this.pollTextsStopped = true;

    if (isDefinedAndNotNull(this.pollTextsTimeoutId))
      clearTimeout(this.pollTextsTimeoutId);
  }

  polledTextProcessed(polledText, combinedContact, isNew) {
    if (!isNew
      || !polledText.isIncoming
      || ((this.props.selectedContact === combinedContact) && document.hasFocus()))
      return;

    this.newTextNotifications.show(polledText, combinedContact, () => {
      try {
        window.focus();
      }
      catch (e) { }

      this.contactSelected(combinedContact);
    });
  }

  pollTexts() {
    Text.poll(this.props.session.auth.token)
      .then(polledTexts => {
        if (this.pollTextsStopped)
          return;

        this.props.session.combinedContactList.processPolledTexts(polledTexts, this.polledTextProcessed);

        this.scheduleNextPollTexts();
      })
      .catch(error => {
        if (this.pollTextsStopped)
          return;

        this.scheduleNextPollTexts();
      });
  }

  updateDocumentTitle() {
    let unreadCount = 0;

    this.props.session.combinedContactList.combinedContacts.forEach(c => {
      unreadCount += c.unreadTexts.incomingCount;
    });

    setDocumentTitle(getDocumentTitle(unreadCount));
  }

  render() {
    const selectedContact = this.props.selectedContact;

    this.updateDocumentTitle();

    const contacts = (this.props.session.combinedContactList.combinedContacts).map(combinedContact =>
      <ItemDiv key={combinedContact.generatedId}>
        <ContactListItemView
          combinedContact={combinedContact}
          selected={this.contactSelected}
          isActive={selectedContact === combinedContact}
        />
      </ItemDiv>);

    return (
      <div className="contact-list">
        <div className="list-header">
          <div className="inner">
            Contacts<PlusIcon size="20" className="icon-button add-contact" onClick={this.showAddContactModal} />
            <AddContactModal
              session={this.props.session}
              shown={this.state.addContactModalShown}
              hide={this.hideAddContactModal}
              contactAdded={this.contactAdded}
            />
          </div>
        </div>
        <div className="contacts-container">
          <Scrollbars ref={this.scrollbars}>
            <PoseGroup>
              {contacts}
            </PoseGroup>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default observer(ContactListView);