function fileSizeToText(size, decimals) {
  if (size === 0)
    return '0 B';

  var k = 1000;
  var dm = decimals + 1 || 1;
  var sizes = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  var i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export { fileSizeToText };

