import React, { Component } from 'react';
import HeaderView from '../components/HeaderView';
import ContactListView from '../components/ContactListView';
import WelcomeView from '../components/WelcomeView';
import DialogView from '../components/DialogView';
import posed, { PoseGroup } from 'react-pose';

import './AuthorizedScreen.scss';

const DialogDiv = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 }
});

class AuthorizedScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCombinedContact: null
    };

    this.logOut = this.logOut.bind(this);
    this.contactSelected = this.contactSelected.bind(this);
  }

  logOut() {
    this.props.session.auth.logout()
      .catch(() => { });

    if (this.props.unauthorized)
      this.props.unauthorized();
  }

  contactSelected(combinedContact) {
    this.setState({
      selectedCombinedContact: combinedContact
    });
  }

  render() {
    const session = this.props.session;
    const selectedCombinedContact = this.state.selectedCombinedContact;

    return (
      <div className="wrapper">
        <div className="header-wrapper">
          <HeaderView session={session} logoClicked={this.contactSelected} logOutClicked={this.logOut} />
        </div>
        <div className="content-wrapper">
          <div className="contact-list-wrapper">
            <ContactListView session={session} selectedContact={selectedCombinedContact} contactSelected={this.contactSelected} />
          </div>
          <div className="page-wrapper">
              <PoseGroup>
                {selectedCombinedContact
                  ? (<DialogDiv key="dialog" className="dialog-wrapper">
                    <DialogView session={session} combinedContact={this.state.selectedCombinedContact} />
                  </DialogDiv>)
                  : (<DialogDiv key="welcome">
                    <WelcomeView />
                  </DialogDiv>)
                }
              </PoseGroup>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthorizedScreen;
