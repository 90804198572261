import React from 'react';
import LoaderIcon from 'react-feather/dist/icons/loader';

import './Spinner.scss';

class Spinner extends React.Component {
  render() {
    return (<LoaderIcon className="spinner" size={this.props.size || 24} />);
  }
}

export default Spinner;