import React from 'react';
import { observer } from 'mobx-react';
import posed, {PoseGroup} from "react-pose";

import './ContactListItemView.scss';

const UnreadTextsDiv = posed.div({
  enter: {
      x: -10
    , opacity: 0
    , transition: {
        x: { from: -10, to: 0, duration: 150 }
      , opacity: { from: 0, to: 1, duration: 100 }
    }
  }
  , exit: {
      x: 0
    , opacity: 1
    , transition: {
        x: { from: 0, to: -2, duration: 100, delay: 300 }
      , opacity: { from: 1, to: 0, duration: 100, delay: 300 }
    }
  }
});

class ContactListItemView extends React.Component {
  clicked = () => {
    if (this.props.selected)
      this.props.selected(this.props.combinedContact);
  };

  render() {
    const combinedContact = this.props.combinedContact;
    const unreadTextsCount = combinedContact.unreadTexts.incomingCount;

    let cls = 'contact-list-item';

    if (this.props.isActive)
      cls += ' active';

    if (unreadTextsCount)
      cls += ' has-unread-texts';

    return (
      <div className={cls} onClick={this.clicked}>
        <div className="name">
          <PoseGroup>
            {unreadTextsCount
              ? (<UnreadTextsDiv key="unread-texts" className="unread-texts">{unreadTextsCount}</UnreadTextsDiv>)
              : null}
          </PoseGroup>
          {combinedContact.name}
        </div>
        <div className="phone-number">
          {combinedContact.phoneNumber}
        </div>
      </div>
    );
  }
}

export default observer(ContactListItemView);