import { executeListObjectsRequest } from '../core/Request.js';

class DataObject {
    static getAll(token) {
        return executeListObjectsRequest({
            request: this.requestBase
          , token
          , responseDataClass: this
        });
    }
}

export default DataObject;