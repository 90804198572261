import DataObject from './DataObject';
import Contact from './Contact';
import { combineUrlParts } from '../helpers/Url';

class Dialog extends DataObject {
  static requestBase = 'dialogs';

  constructor({ id, userPhoneNumber, contactPhoneNumber, contact } = {}) {
    super();

    this.id = id;
    this.userPhoneNumber = userPhoneNumber;
    this.contactPhoneNumber = contactPhoneNumber;
    this.contact = contact;
  }

  static fromRawData({ id, internal_number, external_number, contact }) {
    return new Dialog({
        id
      , userPhoneNumber: internal_number
      , contactPhoneNumber: external_number
      , contact: contact ? Contact.fromRawData(contact) : null
    });
  }

  getTextsRequestUrl() {
    return combineUrlParts(Dialog.requestBase, this.id, 'messages');
  }
}

export default Dialog;