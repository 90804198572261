import React from 'react';
import { Button, Form, FormGroup, Input } from 'reactstrap';

export default class LoginForm extends React.Component {
  state = {
      username: ''
    , password: ''
  };

  validateForm() {
    return this.state.username.trim().length > 0 && this.state.password.trim().length > 0;
  }

  handleSubmit = event => {
    event.preventDefault();

    const submitted = this.props.submitted;

    if (submitted)
      submitted({
          username: this.state.username
        , password: this.state.password
      });
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Input type="email" name="username" placeholder="Username"
                 autoFocus
                 value={this.state.username}
                 onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Input type="password" name="password" placeholder="Password"
                 value={this.state.password} onChange={this.handleChange} />
        </FormGroup>

        <Button type="submit" color="primary" block disabled={!this.validateForm()}>Sign in</Button>
      </Form>
    );
  }
}