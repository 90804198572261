import React from 'react';
import { observer } from 'mobx-react';
import EditIcon from 'react-feather/dist/icons/edit';
import UpdateContactModal from '../modals/UpdateContactModal';

import './DialogHeader.scss';

class DialogHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        selectedContact: null
      , addContactModalShown: false
    };

    this.showUpdateContactModal = this.showUpdateContactModal.bind(this);
    this.hideUpdateContactModal = this.hideUpdateContactModal.bind(this);
  }

  showUpdateContactModal() {
    this.setState({
      updateContactModalShown: true
    });
  }

  hideUpdateContactModal() {
    this.setState({
      updateContactModalShown: false
    });
  }

  render() {
    const combinedContact = this.props.combinedContact;

    return (
      <div className="dialog-header">
        {combinedContact.name}<EditIcon size="20" className="icon-button edit-btn" onClick={this.showUpdateContactModal} />
        <UpdateContactModal
          session={this.props.session}
          shown={this.state.updateContactModalShown}
          hide={this.hideUpdateContactModal}
          combinedContact={combinedContact}
        />
      </div>
    );
  }
}

export default observer(DialogHeader);