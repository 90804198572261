import React from 'react';
import ContactModal from './ContactModal';
import Contact from '../data/Contact';

class UpdateContactModal extends React.Component {
  constructor(props) {
    super(props);

    this.executeSubmitRequest = this.executeSubmitRequest.bind(this);
  }

  executeSubmitRequest({ phoneNumber, name }) {
    const combinedContact = this.props.combinedContact;

    let oldName = '';

    if (combinedContact.contact && combinedContact.contact.name)
      oldName = combinedContact.contact.name;

    if (name === oldName)
      return Promise.resolve();

    if (combinedContact.contact)
      return combinedContact.contact.updateName(this.props.session.auth.token, name);

    return Contact.add(this.props.session.auth.token, { name, phoneNumber })
      .then((contact) => {
        combinedContact.contact = contact;
      });
  }

  render() {
    const combinedContact = this.props.combinedContact;

    const initialValues = {
        phoneNumber: combinedContact.phoneNumber
      , name: ''
    };

    if (combinedContact.contact && combinedContact.contact.name)
      initialValues.name = combinedContact.contact.name;

    return (
      <ContactModal
        title="Update Contact"
        submitBtnTitle="Update"
        submitErrorText="Failed to update the contact"
        phoneNumberIsReadonly={true}
        shown={this.props.shown}
        hide={this.props.hide}
        initialValues={initialValues}
        session={this.props.session}
        executeSubmitRequest={this.executeSubmitRequest}
      />
    );
  }
}

export default UpdateContactModal;