import React, { Fragment } from 'react';
import DialogHeader from './DialogHeader';
import DialogTextsView from './DialogTextsView';
import MiddleCenter from './MiddleCenter';
import OutgoingTextEditor from './OutgoingTextEditor';

import './DialogView.scss';

class DialogView extends React.Component {
  constructor(props) {
    super(props);

    this.dialogTextsView = React.createRef();

    this.sendText = this.sendText.bind(this);
  }

  sendText({ text, attachment }) {
    this.props.combinedContact.sendText({ session: this.props.session, text, attachment });

    setTimeout(() => {
      this.dialogTextsView.current.scrollToBottom();
    });
  }

  static renderInfo(alert, description) {
    return (
      <MiddleCenter className="info">
        <span>{alert}</span> {description}
      </MiddleCenter>
    );
  }

  render() {
    const session = this.props.session;
    const combinedContact = this.props.combinedContact;

    const phoneNumber = session.phoneNumber;

    return (
      <Fragment>
        <div className="dialog-header-wrapper">
          <div className="inner">
            <DialogHeader session={session} combinedContact={combinedContact} />
          </div>
        </div>
        <div className="dialog-texts-wrapper">
          <DialogTextsView ref={this.dialogTextsView} session={session} combinedContact={combinedContact} />
        </div>
        <div className="dialog-outgoing-text-wrapper">
            {!phoneNumber
              ? DialogView.renderInfo('No phone number', 'Your account does not have a phone number set up - please contact your administrator.')
              : (combinedContact.dialog && (combinedContact.dialog.userPhoneNumber !== phoneNumber.phoneNumber)
                ? DialogView.renderInfo('Archived chat', `The phone number you used - ${combinedContact.dialog.userPhoneNumber} - is no longer your primary phone number.`)
                : (<OutgoingTextEditor sendText={this.sendText} textDraft={combinedContact.outgoingTextDraft} />))}
        </div>
      </Fragment>
    );
  }
}

export default DialogView;