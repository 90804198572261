import React, { Component } from 'react';
import UnauthorizedScreen from './screens/UnauthorizedScreen';
import AuthorizedScreen from './screens/AuthorizedScreen';
import posed, { PoseGroup } from 'react-pose';

const View = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 }
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
        authorized: false
      , session: null
    };

    this.authorized = this.authorized.bind(this);
    this.unauthorized = this.unauthorized.bind(this);
  }

  authorized(session) {
    this.setState({
        authorized: true
      , session
    });
  }

  unauthorized() {
    this.setState({
        authorized: false
      , session: null
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.session && (prevState.session !== this.state.session))
      prevState.session.combinedContactList.destroy();
  }

  render() {
    const authorized = this.state.authorized;

    return (
      <PoseGroup>
        <View key={authorized ? "authorized" : "unauthorized"} className="h-100">
          {authorized
            ? <AuthorizedScreen unauthorized={this.unauthorized} session={this.state.session} />
            : <UnauthorizedScreen authorized={this.authorized} />}
        </View>
      </PoseGroup>
    );
  }
}

export default App;
