function ensureString(s) {
  return typeof s === 'undefined' || s === null ? '' : s + '';
}

function trimAsString(s) {
  return ensureString(s).trim();
}

function stripNonDigits(str) {
  return str.replace(/\D/g, '');
}

function insertString(str, index, substr) {
  return str.slice(0, index) + substr + str.slice(index);
}

export { ensureString, trimAsString, stripNonDigits, insertString };